import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { FormattedMessage } from 'react-intl';

type NotFoundPartnerProps = {};

const NotFoundPartner = (props: NotFoundPartnerProps) => (
    <NotFoundState
        outerClassName='border-none'
        headline={<FormattedMessage id='intl-msg:network.partnersTable.emptySearch.title' />}
        message={<FormattedMessage id='intl-msg:network.partnersTable.emptySearch.message' />}
    />
);

export default NotFoundPartner;

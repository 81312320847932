import { FormattedMessage } from 'react-intl';

const ScreenshotOfAddPartnerButton = () => (
    <div className='pointer-events-none user-select-none padding-15 bg-lighter'>
        <button className='btn btn-primary' role='button'>
            <span className='rioglyph rioglyph-plus' />
            <FormattedMessage id='intl-msg:network.label.addPartner' />
        </button>
    </div>
);

export default ScreenshotOfAddPartnerButton;

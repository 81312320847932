import get from 'lodash/fp/get';
import { IntlShape, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import EmptyPartner from './EmptyPartner';
import NotFoundPartner from './NotFoundPartner';
import { Partner } from '../../services/partnerNetwork.types';
import { getSelectedPartnerId, partnerSelected } from '../../layout/appSlice';
import PartnershipStatus from '../../components/PartnershipStatus';
import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import Counts from '../../components/Counts';
import { featureToggles } from '../../configuration/setup/featureToggles';
import { identity } from 'io-ts';
import { dataSharingAgreements } from '../../data/demoData';

type PartnerTableProps = {
    partners: Partner[];
    isEmptyList: boolean;
    isNotFoundList: boolean;
};

const tableClasses = `table table-bordered table-layout-fixed table-column-overflow-hidden 
    table-hover table-sticky table-head-filled`;

const makeColumns = (intl: IntlShape) =>
    [
        {
            title: intl.formatMessage({ id: 'intl-msg:network.label.companyName' }),
            field: 'name',
            styles: { minWidth: '100px' },
        },
        {
            title: intl.formatMessage({ id: 'intl-msg:network.label.account' }),
            field: 'accountProfile.displayName',
            styles: { minWidth: '100px' },
        },
        {
            title: intl.formatMessage({ id: 'intl-msg:network.label.description' }),
            field: 'description',
            styles: { minWidth: '100px' },
        },
        {
            title: intl.formatMessage({ id: 'intl-msg:network.label.status' }),
            field: 'status',
            styles: { minWidth: '100px' },
            renderer: (partner: Partner) => <PartnershipStatus partner={partner} />,
        },
        featureToggles.poc && {
            title: intl.formatMessage({ id: 'intl-msg:network.label.agreements' }),
            field: 'partnerAgreements',
            styles: { minWidth: '100px' },
            renderer: (partner: Partner) =>
                partner.status !== 'pending' && <Counts count={dataSharingAgreements.length} requestsCount={1} />,
        },
    ].filter(identity);

const PartnerTable = (props: PartnerTableProps) => {
    const { partners, isEmptyList, isNotFoundList } = props;

    const intl = useIntl();
    const dispatch = useAppDispatch();
    const selectedPartnerId = useAppSelector(getSelectedPartnerId);

    const handleSelectPartner = (partner: Partner) => dispatch(partnerSelected(partner.id));

    const columns = makeColumns(intl);

    const emptyFeedback = (isEmptyList || isNotFoundList) && (
        <tr>
            <td colSpan={columns.length} className='shadow-none'>
                {isEmptyList && <EmptyPartner />}
                {isNotFoundList && <NotFoundPartner />}
            </td>
        </tr>
    );

    return (
        <div className=''>
            <table className={tableClasses}>
                <colgroup>
                    {columns.map(column => (
                        <col key={column.field} style={column.styles} />
                    ))}
                </colgroup>
                <thead
                    {...getTrackingAttributes({
                        trigger: 'click',
                        category: TRACKING_CATEGORIES.OVERVIEW,
                        action: 'Table header clicked',
                        label: 'Table header clicked',
                    })}
                >
                    <tr>
                        {columns.map(column => (
                            <th
                                key={column.field}
                                className='user-select-none sort-column'
                                data-field={column.field}
                                data-sortby={column.field}
                                title={column.title}
                            >
                                <span>
                                    <span className='sort-arrows sort-' />
                                    <span>{column.title}</span>
                                </span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {emptyFeedback}
                    {partners.map(partner => {
                        const { id } = partner;
                        const isSelected = selectedPartnerId === id;
                        return (
                            <tr
                                key={id}
                                className={`cursor-pointer ${isSelected ? 'active' : ''}`}
                                onClick={() => handleSelectPartner(partner)}
                                {...getTrackingAttributes({
                                    trigger: 'click',
                                    category: TRACKING_CATEGORIES.OVERVIEW,
                                    action: 'Table row clicked',
                                    label: 'Table row clicked',
                                })}
                            >
                                {columns.map(column => (
                                    <td key={column.field} data-field={column.field}>
                                        <span>
                                            {column.renderer ? column.renderer(partner) : get(column.field, partner)}
                                        </span>
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PartnerTable;

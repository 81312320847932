import { FormattedMessage } from 'react-intl';
import ListMenu from '@rio-cloud/rio-uikit/ListMenu';

import { ServiceInfoDialogMenuItem } from './ServiceInfoDialogMenuItem';
import { SERVICE_INFO_CHAPTER_HELP, SERVICE_INFO_CHAPTER_WELCOME } from '../../layout/appSlice';

const getMenuItems = (activeChapter: string, onClick: React.MouseEventHandler<HTMLElement>) => {
    return [
        {
            group: <FormattedMessage id='intl-msg:network.serviceInfo.title' />,
            navItems: [
                {
                    key: SERVICE_INFO_CHAPTER_WELCOME,
                    item: (
                        <ServiceInfoDialogMenuItem
                            chapter={SERVICE_INFO_CHAPTER_WELCOME}
                            activeChapter={activeChapter}
                            onClick={onClick}
                            trackingLabel='Welcome chapter button'
                        />
                    ),
                },
                {
                    key: SERVICE_INFO_CHAPTER_HELP,
                    item: (
                        <ServiceInfoDialogMenuItem
                            chapter={SERVICE_INFO_CHAPTER_HELP}
                            activeChapter={activeChapter}
                            onClick={onClick}
                            trackingLabel='Setup partnership chapter button'
                        />
                    ),
                },
            ],
        },
    ];
};

type ServiceInfoDialogMenuProps = {
    chapter: string;
    onClick: React.MouseEventHandler<HTMLElement>;
};

const ServiceInfoDialogMenu = ({ chapter, onClick }: ServiceInfoDialogMenuProps) => {
    return <ListMenu menuItems={getMenuItems(chapter, onClick)} groupClassName='padding-left-0' />;
};

export default ServiceInfoDialogMenu;

import React, { useState, useEffect } from 'react';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import Fade from '@rio-cloud/rio-uikit/Fade';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';

import { DataSharingAgreement, dataSharingAgreements } from '../../data/demoData';
import Counts from '../../components/Counts';

type DataSharingAgreementStatusProps = { status: string };
const DataSharingAgreementStatus = ({ status }: DataSharingAgreementStatusProps) => {
    const labelClass = status === 'active' ? 'rioglyph-ok-sign text-color-success' : 'rioglyph-hour-glass';
    return <span className={`rioglyph text-size-18 line-height-20 ${labelClass}`} />;
};

type DataSharingAgreementModeProps = { mode: string };
const DataSharingAgreementMode = ({ mode }: DataSharingAgreementModeProps) => {
    return <span className='label label-muted label-filled label-condensed border-none'>{mode}</span>;
};

type DataSharingAgreementFormProps = {
    dataSharingAgreement: DataSharingAgreement;
};

const getCheckboxClasses = (isSelected: boolean) =>
    `padding-x-15 padding-y-10 user-select-none border rounded ${isSelected ? 'border-color-highlight' : ''}`;

const DataSharingAgreementForm = ({ dataSharingAgreement: dataSharingAgreement }: DataSharingAgreementFormProps) => {
    const [permissionsChanged, setPermissionsChanged] = useState(false);
    const [p1, setP1] = useState(dataSharingAgreement.permissions.length > 0);
    const [p2, setP2] = useState(dataSharingAgreement.permissions.length > 0);
    const [p3, setP3] = useState(dataSharingAgreement.permissions.length > 1);
    const [p4, setP4] = useState(dataSharingAgreement.permissions.length > 1);

    useEffect(() => {
        setPermissionsChanged(
            p1 !== dataSharingAgreement.permissions.length > 0 ||
                p2 !== dataSharingAgreement.permissions.length > 0 ||
                p3 !== dataSharingAgreement.permissions.length > 1 ||
                p4 !== dataSharingAgreement.permissions.length > 1
        );
    }, [p1, p2, p3, p4]);

    return (
        <ExpanderPanel
            bsStyle='default'
            className='margin-top-15'
            titleClassName='width-100pct'
            bodyClassName='padding-0'
            open
            title={
                <div className='display-flex gap-10'>
                    <div>
                        <DataSharingAgreementStatus status={dataSharingAgreement.status} />
                    </div>
                    <div className='display-flex justify-content-between gap-5 width-100pct'>
                        <div>{dataSharingAgreement.name}</div>
                        <SimpleTooltip
                            content={`${dataSharingAgreement.pendingRequests.length} angeforderte Datenfreigabe`}
                            placement='bottom'
                        >
                            <div>
                                <Counts requestsCount={dataSharingAgreement.pendingRequests.length} />
                            </div>
                        </SimpleTooltip>
                    </div>
                </div>
            }
        >
            <>
                {dataSharingAgreement.status === 'pending' && (
                    <p className='margin-0 padding-x-15 padding-top-15 text-color-dark text-italic'>
                        Diese Datenfreigabe ist noch ausstehend und muss von der Gegenseite noch akzeptiert werden.
                    </p>
                )}
                <div className='display-grid grid-cols-2 padding-15'>
                    <div className='display-flex flex-column gap-10'>
                        <div>
                            <label className='margin-0'>Typ</label>
                            <div>{dataSharingAgreement.type}</div>
                        </div>
                        <div>
                            <label className='margin-0'>Role</label>
                            <div>{dataSharingAgreement.role}</div>
                        </div>
                        <div>
                            <label className='margin-0'>Modus</label>
                            <div>
                                <DataSharingAgreementMode mode={dataSharingAgreement.mode} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label className='margin-0'>Datenfreigaben</label>
                            <div className='display-grid gap-15 margin-top-5'>
                                <Checkbox
                                    custom
                                    checked={p1}
                                    onChange={() => setP1(!p1)}
                                    className={getCheckboxClasses(p1)}
                                >
                                    <div className='checkbox-text-wrapper display-flex flex-wrap align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            Positionsdaten
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={p2}
                                    onChange={() => setP2(!p2)}
                                    className={getCheckboxClasses(p2)}
                                >
                                    <div className='checkbox-text-wrapper display-flex flex-wrap align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            Kennzeichen
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={p3}
                                    onChange={() => setP3(!p3)}
                                    className={getCheckboxClasses(p3)}
                                >
                                    <div className='checkbox-text-wrapper display-flex flex-wrap align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            Lieferstatus
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={p4}
                                    onChange={() => setP4(!p4)}
                                    className={getCheckboxClasses(p4)}
                                >
                                    <div className='checkbox-text-wrapper display-flex flex-wrap align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            ETA
                                        </div>
                                        {dataSharingAgreement.pendingRequests.length > 0 && (
                                            <>
                                                <div>
                                                    <span className='label label-filled label-condensed label-primary'>
                                                        Angefordert
                                                    </span>
                                                </div>

                                                <div className='text-size-11 text-color-dark text-italic'>
                                                    Diese Datenfreigabe muss noch von der Gegenseite bestätigt werden,
                                                    bevor die Daten ausgetauscht werden können.
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Checkbox>
                            </div>
                            <Fade show={permissionsChanged} animationStyle='fromTop'>
                                <div className='btn-toolbar padding-top-15'>
                                    <button className='btn btn-primary'>Anwenden</button>
                                    <button className='btn btn-link'>Abbrechen</button>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </>
        </ExpanderPanel>
    );
};

const Agreements = () => {
    return (
        <>
            {dataSharingAgreements.map(partnerAgreement => (
                <DataSharingAgreementForm key={partnerAgreement.id} dataSharingAgreement={partnerAgreement} />
            ))}
        </>
    );
};

export default Agreements;

import React, { useEffect, useRef, useState } from 'react';
// import { FormattedMessage, useIntl } from 'react-intl';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import Button from '@rio-cloud/rio-uikit/Button';
import Fade from '@rio-cloud/rio-uikit/Fade';
import compact from 'lodash/fp/compact';
import isEmpty from 'lodash/fp/isEmpty';
import join from 'lodash/fp/join';

import { useSendFeedbackMutation } from '../../services/contactFormApi';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getUserProfile } from '../../configuration/login/loginSlice';
import { featureToggles } from '../../configuration/setup/featureToggles';

const getCheckboxClasses = (isSelected: boolean) =>
    `padding-15 user-select-none border rounded ${isSelected ? 'border-color-highlight' : ''}`;

const FeedbackPanel = () => {
    const userProfile = useAppSelector(getUserProfile);

    // const intl = useIntl();

    const [showFinalState, setShowFinalState] = useState<boolean>(false);
    const [hideFeedback, setHideFeedback] = useState<boolean>(false);
    const [shareNone, setShareNone] = useState<boolean>(false);
    const [sharePositionData, setSharePositionData] = useState<boolean>(false);
    const [shareDeliveryStatus, setShareDeliveryStatus] = useState<boolean>(false);
    const [shareMasterData, setShareMasterData] = useState<boolean>(false);
    const [shareTransportOrder, setShareTransportOrder] = useState<boolean>(false);
    const [shareDocuments, setShareDocuments] = useState<boolean>(false);
    const [shareBillingData, setShareBillingData] = useState<boolean>(false);
    const [shareOther, setShareOther] = useState<boolean>(false);
    const [shareOtherDetails, setShareOtherDetails] = useState<string>('');
    const [reasons, setReasons] = useState<string>('');

    const [notNeeded, setNotNeeded] = useState<boolean>(false);
    const [otherWays, setOtherWays] = useState<boolean>(false);
    const [helpful, setHelpful] = useState<boolean>(false);
    const [dataTypes, setDataTypes] = useState<boolean>(false);
    const [relevantDataText, setRelevantDataText] = useState<string>('');

    const [callMe, setCallMe] = useState<boolean>(false);
    const [keepInformed, setKeepInformed] = useState<boolean>(false);
    const [acknowledged, setAcknowledged] = useState<boolean>(false);

    const [hasError, setHasError] = useState<boolean>(false);

    const otherDataInputRef = useRef<HTMLInputElement>();
    const relevantDataDetailsInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (otherDataInputRef.current && shareOther) {
            otherDataInputRef.current.focus();
        }
    }, [otherDataInputRef.current, shareOther]);

    useEffect(() => {
        if (relevantDataDetailsInputRef.current && dataTypes) {
            relevantDataDetailsInputRef.current.focus();
        }
    }, [relevantDataDetailsInputRef.current, dataTypes]);

    const [sendFeedbackEmail] = useSendFeedbackMutation();

    const sendFeedback = () => {
        if (!acknowledged) {
            setHasError(true);
            return;
        }

        const message = `[{
            "question": "(1) Welche Daten wollen Sie teilen:",
            "answers": [
            ${join(
                ', ',
                compact([
                    shareNone && '"Keine"',
                    sharePositionData && '"Positionsdaten"',
                    shareDeliveryStatus && '"Lieferstatus / ETA"',
                    shareTransportOrder && '"Lieferaufträge"',
                    shareMasterData && '"Stammdaten"',
                    shareDocuments && '"Dokumente, z.B. Abliefernachweise"',
                    shareBillingData && '"Rechnungsdaten"',
                    shareOther && `"Andere Daten und Dokumente: ${shareOtherDetails && `${shareOtherDetails}`}"`,
                ])
            )}
            ]
        }, {
            "question": "(2) Warum genau wäre es relevant für Sie, diese Daten mit Ihren Partnern über RIO zu teilen?:",
            "answer": "${reasons}"
        }, {
            "question": "(3) Wie fänden Sie es, wenn Sie selbst Daten von Partnern über die RIO-Plattform erhalten könnten?:",
            "answers": [
            ${join(
                ', ',
                compact([
                    notNeeded && '"Nicht relevant - \'Wir benötigen generell keine Daten von unseren Partnern.\'"',
                    otherWays &&
                        '"Nicht relevant - \'Wir bekommen unsere Daten von Partnern bereits auf anderem Wege.\'"',
                    helpful &&
                        '"Relevant - \'Es wäre hilfreich zukünftig Daten von unseren Partnern über die RIO-Plattform zu erhalten.\'"',
                    dataTypes && `"Folgende Daten wären relevant: ${relevantDataText && `${relevantDataText}`}"`,
                ])
            )}
            ]
        }, {
            "question": "(4) Weitere Informationen:",
            "answers": [
            ${join(
                ', ',
                compact([
                    callMe && '"Call Me"',
                    keepInformed && '"Keep me informed"',
                    acknowledged && '"Acknowledged to store data"',
                ])
            )}
            ]
        }]
        `;

        console.log(`Sending email with message \n${message}`);

        if (import.meta.env.PROD) {
            sendFeedbackEmail({ message, userProfile });
        }
        setShowFinalState(true);
    };

    const handleShareOtherDetailsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setShareOtherDetails(value);
        if (!(isEmpty(value) || shareOther)) {
            setShareOther(true);
        }
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => setReasons(event.target.value);

    const handleRelevantDataTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setRelevantDataText(value);
        if (!(isEmpty(value) || dataTypes)) {
            setDataTypes(true);
        }
    };

    const handleAcknowledgement = () => {
        setAcknowledged(!acknowledged);
        if (hasError) {
            setHasError(false);
        }
    };

    if (hideFeedback || featureToggles.hideFeedback) {
        return null;
    }

    if (showFinalState) {
        return (
            <Fade>
                <div className={'margin-top-50 panel panel-default panel-body shadow-default padding-25 margin-top-20'}>
                    <div className='display-grid place-items-center'>
                        <div className='max-width-800'>
                            <div className='text-center text-size-h3 text-color-dark'>
                                Vielen Dank für Ihr Feedback.
                            </div>
                            <div className='display-grid place-items-center margin-top-20'>
                                <Button onClick={() => setHideFeedback(true)}>Schließen</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }

    return (
        <div className={'margin-top-50 panel panel-default panel-body shadow-default padding-25 margin-top-20'}>
            <div className='display-grid place-items-center'>
                <div className='max-width-800'>
                    <div className='text-center'>
                        <div className='text-size-h3 text-uppercase text-color-primary'>
                            {/*<FormattedMessage id="intl-msg:network.feedback.title" />*/}
                            {/*Wir brauchen Ihr Feedback*/}
                            Das neue Partnernetzwerk "Connect &amp; Share"
                            <br />
                            Ihr Feedback zählt!
                        </div>
                        <p className='text-size-14'>
                            {/*<FormattedMessage id="intl-msg:network.feedback.intro" />*/}
                            Derzeit wird hier die neue Anwendung "Connect &amp; Share" entwickelt. Lassen Sie uns
                            wissen, welche Funktionalität zum Datenaustausch Ihnen am meisten hilft, Ihre täglichen
                            Aufgaben und Prozesse zu vereinfachen.
                        </p>
                    </div>
                    <div className='counter size-small margin-top-50'>
                        <div className='display-block margin-20' data-count='1'>
                            <div className='text-size-16'>
                                {/*<FormattedMessage id="intl-msg:network.feedback.question1" />*/}
                                Welche Fahrzeug- oder Auftragsdaten würden Sie gerne über RIO mit Ihren Partnern teilen?
                            </div>
                            <div
                                className={
                                    'display-grid grid-auto-rows ' +
                                    'grid-cols-2-ls grid-cols-4-sm grid-cols-1-xs gap-15 margin-top-5'
                                }
                            >
                                <Checkbox
                                    custom
                                    checked={shareNone}
                                    onChange={() => setShareNone(!shareNone)}
                                    className={getCheckboxClasses(shareNone)}
                                >
                                    <div className='checkbox-text-wrapper display-flex align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            {/*<FormattedMessage id="intl-msg:network.feedback.question1.none" />*/}
                                            Keine
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={sharePositionData}
                                    onChange={() => setSharePositionData(!sharePositionData)}
                                    className={getCheckboxClasses(sharePositionData)}
                                >
                                    <div className='checkbox-text-wrapper display-flex align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            {/*<FormattedMessage id="intl-msg:network.feedback.question1.positionData" />*/}
                                            Positionsdaten
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={shareDeliveryStatus}
                                    onChange={() => setShareDeliveryStatus(!shareDeliveryStatus)}
                                    className={getCheckboxClasses(shareDeliveryStatus)}
                                >
                                    <div className='checkbox-text-wrapper display-flex align-items-center gap-10'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            {/*<FormattedMessage id="intl-msg:network.feedback.question1.deliveryStatus" />*/}
                                            Lieferstatus / ETA
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={shareTransportOrder}
                                    onChange={() => setShareTransportOrder(!shareTransportOrder)}
                                    className={getCheckboxClasses(shareTransportOrder)}
                                >
                                    <div className='checkbox-text-wrapper display-flex align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            {/*<FormattedMessage id="intl-msg:network.feedback.question1.transportOrders" />*/}
                                            Transportaufträge
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={shareMasterData}
                                    onChange={() => setShareMasterData(!shareMasterData)}
                                    className={getCheckboxClasses(shareMasterData)}
                                >
                                    <div className='checkbox-text-wrapper display-flex align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            {/*<FormattedMessage id="intl-msg:network.feedback.question1.masterData" />*/}
                                            Stammdaten
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={shareBillingData}
                                    onChange={() => setShareBillingData(!shareBillingData)}
                                    className={getCheckboxClasses(shareBillingData)}
                                >
                                    <div className='checkbox-text-wrapper display-flex align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            {/*<FormattedMessage id="intl-msg:network.feedback.question1.billingData" />*/}
                                            Rechnungsdaten
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={shareDocuments}
                                    onChange={() => setShareDocuments(!shareDocuments)}
                                    className={`${getCheckboxClasses(shareDocuments)} grid-colspan-2`}
                                >
                                    <div className='checkbox-text-wrapper display-flex align-items-center gap-10 height-100pct'>
                                        <div className='checkbox-text' />
                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            {/*<FormattedMessage id="intl-msg:network.feedback.question1.documents" />*/}
                                            Dokumente, z.B. Abliefernachweise
                                        </div>
                                    </div>
                                </Checkbox>
                            </div>
                            <div className='margin-top-15 position-relative'>
                                <Checkbox
                                    custom
                                    checked={shareOther}
                                    onChange={() => setShareOther(!shareOther)}
                                    className={getCheckboxClasses(shareOther)}
                                >
                                    <div className='checkbox-text-wrapper display-flex gap-10 height-70'>
                                        <div className='checkbox-text' />

                                        <div className='text-medium text-color-darker word-break-all hyphens-auto'>
                                            {/*<FormattedMessage id="intl-msg:network.feedback.question1.other" />*/}
                                            Andere Daten oder Dokumente
                                        </div>
                                    </div>
                                </Checkbox>
                                <div
                                    className={
                                        'form-group margin-top-10 margin-bottom-0 ' +
                                        'position-absolute left-0 right-0 padding-x-15'
                                    }
                                    style={{ top: '40px' }}
                                >
                                    <input
                                        ref={otherDataInputRef}
                                        className={`form-control ${shareOtherDetails ? '' : 'border-color-lighter'}`}
                                        /*placeholder={intl.formatMessage({
                                            id: 'intl-msg:network.feedback.question1.other.placeholder',
                                        })}*/
                                        placeholder='Welche anderen Daten und Dokumente würden Sie teilen wollen?'
                                        onChange={handleShareOtherDetailsChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='counter size-small margin-top-50'>
                        <div className='display-block margin-20' data-count='2'>
                            <div className='text-size-16'>
                                {/*<FormattedMessage id="intl-msg:network.feedback.question2" />*/}
                                Warum genau wäre es relevant für Sie, diese Daten mit Ihren Partnern über RIO zu teilen?
                            </div>
                            <div className='form-group margin-top-10 margin-bottom-0'>
                                <input
                                    className='form-control'
                                    /*placeholder={intl.formatMessage({
                                        id: 'intl-msg:network.feedback.question2.placeholder',
                                    })}*/
                                    placeholder='Wie würde Ihnen das Teilen der Daten weiterhelfen?'
                                    onChange={handleReasonChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='counter size-small margin-top-50'>
                        <div className='display-block margin-20' data-count='3'>
                            <div className='text-size-16'>
                                {/*<FormattedMessage id="intl-msg:network.feedback.question3" />*/}
                                Wie fänden Sie es, wenn Sie selbst Daten von Partnern über die RIO-Plattform erhalten
                                könnten?
                            </div>
                            <div className={'display-grid grid-auto-rows ' + 'grid-cols-1 gap-15 margin-top-5'}>
                                <Checkbox
                                    custom
                                    checked={notNeeded}
                                    onChange={() => setNotNeeded(!notNeeded)}
                                    className={getCheckboxClasses(notNeeded)}
                                >
                                    <div className='checkbox-text-wrapper display-flex gap-10'>
                                        <div className='checkbox-text' />
                                        <div className='margin-right-15'>
                                            <div className='text-color-darker'>
                                                {/*<FormattedMessage id="intl-msg:network.feedback.question3.notRelevant" />*/}
                                                Nicht relevant
                                                {' - '}
                                                <span className='text-medium'>
                                                    {/*<FormattedMessage id="intl-msg:network.feedback.question3.notNeeded" />*/}
                                                    "Wir benötigen generell keine Daten von unseren Partnern."
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={otherWays}
                                    onChange={() => setOtherWays(!otherWays)}
                                    className={getCheckboxClasses(otherWays)}
                                >
                                    <div className='checkbox-text-wrapper display-flex gap-10'>
                                        <div className='checkbox-text' />
                                        <div className='margin-right-15'>
                                            <div className='text-color-darker'>
                                                {/*<FormattedMessage id="intl-msg:network.feedback.question3.notRelevant" />*/}
                                                Nicht relevant
                                                {' - '}
                                                <span className='text-medium'>
                                                    {/*<FormattedMessage id="intl-msg:network.feedback.question3.otherWays" />*/}
                                                    "Wir bekommen unsere Daten von Partnern bereits auf anderem Wege."
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Checkbox>
                                <Checkbox
                                    custom
                                    checked={helpful}
                                    onChange={() => setHelpful(!helpful)}
                                    className={getCheckboxClasses(helpful)}
                                >
                                    <div className='checkbox-text-wrapper display-flex gap-10'>
                                        <div className='checkbox-text' />
                                        <div className='margin-right-15'>
                                            <div className='text-color-darker'>
                                                {/*<FormattedMessage id="intl-msg:network.feedback.question3.relevant" />*/}
                                                Relevant
                                                {' - '}
                                                <span className='text-medium'>
                                                    {/*<FormattedMessage id="intl-msg:network.feedback.question3.helpful" />*/}
                                                    "Es wäre hilfreich zukünftig Daten von unseren Partnern über die
                                                    RIO-Plattform zu erhalten."
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Checkbox>
                            </div>
                            <div className='margin-top-15 position-relative'>
                                <Checkbox
                                    custom
                                    checked={dataTypes}
                                    onChange={() => setDataTypes(!dataTypes)}
                                    className={getCheckboxClasses(dataTypes)}
                                >
                                    <div className='checkbox-text-wrapper display-flex gap-10 height-70'>
                                        <div className='checkbox-text' />
                                        <div className='margin-right-15'>
                                            <div className='text-medium text-color-darker'>
                                                {/*<FormattedMessage id="intl-msg:network.feedback.question3.relevantData" />*/}
                                                Folgende Daten wären relevant
                                            </div>
                                        </div>
                                    </div>
                                </Checkbox>
                                <div
                                    className={
                                        'form-group margin-top-10 margin-bottom-0 ' +
                                        'position-absolute left-0 right-0 padding-x-15'
                                    }
                                    style={{ top: '40px' }}
                                >
                                    <input
                                        ref={relevantDataDetailsInputRef}
                                        className={`form-control ${dataTypes ? '' : 'border-color-lighter'}`}
                                        /*placeholder={intl.formatMessage({
                                            id: 'intl-msg:network.feedback.question3.relevantData.placeholder',
                                        })}*/
                                        placeholder='Welche Daten würden Sie gerne erhalten?'
                                        onChange={handleRelevantDataTextChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='margin-top-25 margin-x-20'>
                        <Checkbox checked={callMe} onChange={() => setCallMe(!callMe)}>
                            <span className='user-select-none'>
                                {/*<FormattedMessage id="intl-msg:network.feedback.checkoutQuestion1" />*/}
                                Ich stehe für weiterführende Fragen zur Verfügung. Hierzu dürfen Sie mich kontaktieren.
                            </span>
                        </Checkbox>
                        <Checkbox checked={keepInformed} onChange={() => setKeepInformed(!keepInformed)}>
                            <span className='user-select-none'>
                                {/*<FormattedMessage id="intl-msg:network.feedback.checkoutQuestion2" />*/}
                                Ich möchte über Neuerungen zur Funktion "Connect &amp; Share" informiert bleiben. Hierzu
                                dürfen Sie mich kontaktieren.
                            </span>
                        </Checkbox>
                    </div>
                    <div className='margin-top-25 margin-x-20'>
                        <div className={`form-group ${hasError ? 'has-feedback has-error' : ''}`}>
                            <Checkbox checked={acknowledged} onChange={handleAcknowledgement}>
                                <span className='user-select-none'>
                                    {/*<FormattedMessage id="intl-msg:network.feedback.checkoutQuestion3" />*/}
                                    Ich bin damit einverstanden, dass die in diesem Formular eingegebenen Daten in
                                    diesem RIO-Account gespeichert werden.
                                    {'*'}
                                </span>
                            </Checkbox>
                            {hasError && (
                                <div className='help-block position-relative'>
                                    {/*<FormattedMessage id="intl-msg:network.feedback.checkoutQuestion3.validation.notChecked" />*/}
                                    Bitte bestätigen Sie, dass Ihre Daten zur Auswertung gespeichert werden dürfen.
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='display-flex justify-content-center margin-top-20'>
                        <Button bsStyle='primary' onClick={sendFeedback}>
                            <span className='rioglyph rioglyph-send' />
                            {/*<FormattedMessage id="intl-msg:network.feedback.send" />*/}
                            Feedback senden
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackPanel;

import CustomState from '@rio-cloud/rio-uikit/CustomState';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

type LoadingInvitationStateProps = {};

const LoadingInvitationState = (props: LoadingInvitationStateProps) => {
    return <CustomState fullWidth outerClassName='shadow-default' message={<ContentLoader />} />;
};

export default LoadingInvitationState;

import { FormattedMessage, useIntl } from 'react-intl';

const ScreenshotOfAddPartner = () => {
    const intl = useIntl();
    return (
        <div className='pointer-events-none user-select-none bg-lighter'>
            <div className='modal show modal-enter-done position-relative'>
                <div className='modal-dialog modal-sm margin-15'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <div className='modal-header-text'>
                                <div className='modal-header-title'>
                                    <FormattedMessage id='intl-msg:network.addPartner.step1.title' />
                                </div>
                            </div>
                            <div className='modal-header-buttons'>
                                <button type='button' className='btn btn-icon-only btn-muted modal-header-close close'>
                                    <span className='rioglyph rioglyph-remove' />
                                </button>
                            </div>
                        </div>
                        <div className='modal-body display-flex'>
                            <div className='display-flex align-items-center width-100pct'>
                                <div
                                    className='margin-right-25 bg-lightest rounded-circle padding-25 
                    display-grid place-items-center text-size-16'
                                >
                                    <span className='rioglyph rioglyph-user text-color-dark text-size-200pct' />
                                </div>
                                <div className='flex-1-1 form-group margin-bottom-15 '>
                                    <label className='control-label'>
                                        <FormattedMessage id='intl-msg:network.label.partnerName' />
                                    </label>
                                    <div className='ClearableInput input-group'>
                                        <input
                                            placeholder={intl.formatMessage({
                                                id: 'intl-msg:network.addPartner.nameInput.placeholder',
                                            })}
                                            className='form-control'
                                            type='text'
                                            value=''
                                        />
                                        <span className='clearButton hide'>
                                            <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <div className='btn-toolbar justify-content-end'>
                                <button type='button' className='btn btn-default btn-component'>
                                    <FormattedMessage id='intl-msg:network.label.cancel' />
                                </button>
                                <button type='button' className='btn btn-primary disabled btn-component btn-icon-right'>
                                    <span className='rioglyph rioglyph-chevron-right' />
                                    <FormattedMessage id='intl-msg:network.label.addPartner' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ScreenshotOfAddPartner;

import { FormattedMessage } from 'react-intl';

import { SERVICE_INFO_CHAPTER_HELP } from '../../layout/appSlice';
import ScreenshotOfAddPartnerButton from './ScreenshotOfAddPartnerButton';
import ScreenshotOfAddPartner from './ScreenshotOfAddPartner';
import ScreenshotOfPartners from './ScreenshotOfPartners';
import ScreenshotOfShareInvitationLink from './ScreenshotOfShareInvitationLink';

type ServiceInfoDialogPagesProps = {
    chapter: string;
};

const Headline = ({ children }: { children: React.ReactElement }) => (
    <div className='text-size-h2 text-medium margin-bottom-10'>{children}</div>
);

const WelcomePage = () => (
    <div>
        <Headline>
            <FormattedMessage id='intl-msg:network.serviceInfo.welcome' />
        </Headline>
        <p>
            <FormattedMessage id='intl-msg:network.serviceInfo.welcome.phrase1' />
        </p>
        <p>
            <FormattedMessage id='intl-msg:network.serviceInfo.welcome.phrase2' />
        </p>
        <ScreenshotOfPartners />
    </div>
);

const FirstStepsPage = () => (
    <div>
        <Headline>
            <FormattedMessage id='intl-msg:network.serviceInfo.firstSteps' />
        </Headline>
        <p>
            <FormattedMessage id='intl-msg:network.serviceInfo.firstSteps.phrase1' />
        </p>
        <ScreenshotOfAddPartnerButton />
        <p>
            <FormattedMessage id='intl-msg:network.serviceInfo.firstSteps.phrase2' />
        </p>
        <ScreenshotOfAddPartner />
        <p>
            <FormattedMessage id='intl-msg:network.serviceInfo.firstSteps.phrase3' />
        </p>
        <ScreenshotOfShareInvitationLink />
        <p>
            <FormattedMessage id='intl-msg:network.serviceInfo.firstSteps.phrase4' />
        </p>
    </div>
);

const ServiceInfoDialogPages = ({ chapter }: ServiceInfoDialogPagesProps) => {
    if (chapter === SERVICE_INFO_CHAPTER_HELP) {
        return <FirstStepsPage />;
    }
    return <WelcomePage />;
};

export default ServiceInfoDialogPages;

import { v4 as uuidv4 } from 'uuid';

export type Request = {
    id: string;
};

export type Permission = {
    id: string;
};

export type DataSharingAgreement = {
    id: string;
    name: string;
    type: string;
    role: string;
    status: string;
    mode: string;
    pendingRequests: Request[];
    permissions: Permission[];
};

export const dataSharingAgreements: DataSharingAgreement[] = [
    {
        id: uuidv4(),
        name: 'Datenfreigabe 1',
        type: 'ORDER_EXCHANGE',
        role: 'SENDER',
        status: 'active',
        mode: 'AUTOMATIC',
        pendingRequests: [],
        permissions: [{ id: uuidv4() }, { id: uuidv4() }],
        contract: [],
    },
    {
        id: uuidv4(),
        name: 'Datenfreigabe 2',
        type: 'ORDER_EXCHANGE',
        role: 'SENDER',
        status: 'active',
        mode: 'AUTOMATIC',
        pendingRequests: [{ id: uuidv4() }],
        permissions: [{ id: uuidv4() }],
        contract: [],
    },
    {
        id: uuidv4(),
        name: 'Datenfreigabe 3',
        type: 'ORDER_EXCHANGE',
        role: 'SENDER',
        status: 'pending',
        mode: 'MANUAL',
        pendingRequests: [],
        permissions: [{ id: uuidv4() }],
        contract: [],
    },
];

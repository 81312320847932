import { useState } from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import size from 'lodash/fp/size';
import isEmpty from 'lodash/fp/isEmpty';
import filter from 'lodash/fp/filter';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';

import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { getPartnerList, getShowCreateDialog, showInvitationDialog } from '../../layout/appSlice';
import CreatePartnerDialog from '../createPartnerDialog/CreatePartnerDialog';
import PartnerTable from './PartnerTable';
import type { Partner } from '../../services/partnerNetwork.types';
import HelpButton from '../helpButton/HelpButton';
import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';

const Partners = () => {
    const [searchValue, setSearchValue] = useState<string>('');

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const showCreatePartnerDialog = useAppSelector(getShowCreateDialog);
    const partners = useAppSelector(getPartnerList);

    const handleCreatePartner = () => dispatch(showInvitationDialog({ showDialog: true }));
    const handleCloseCreatePartnerDialog = () => dispatch(showInvitationDialog({ showDialog: false }));

    const partnerList = partners?.items || [];

    const filteredPartners = isEmpty(searchValue)
        ? partnerList
        : filter((partner: Partner) => {
              return partner?.name.includes(searchValue);
          })(partnerList);

    const totalPartners = size(filteredPartners);

    const isEmptyList = isEmpty(partnerList) && isEmpty(searchValue);
    const isNotFoundList = isEmpty(filteredPartners) && !isEmpty(searchValue);

    return (
        <div>
            <CreatePartnerDialog show={showCreatePartnerDialog} onClose={handleCloseCreatePartnerDialog} />
            <TableToolbar>
                <div className='table-toolbar-container'>
                    <div className='table-toolbar-group-left'>
                        <div className='table-toolbar-column'>
                            <div className='btn-toolbar table-btn-toolbar'>
                                <button
                                    className='btn btn-primary'
                                    role='button'
                                    onClick={handleCreatePartner}
                                    {...getTrackingAttributes({
                                        trigger: 'click',
                                        category: TRACKING_CATEGORIES.OVERVIEW,
                                        action: 'Add partner clicked',
                                        label: 'Add partner clicked',
                                    })}
                                >
                                    <span className='rioglyph rioglyph-plus' />
                                    <FormattedMessage id='intl-msg:network.label.addPartner' />
                                </button>
                                <HelpButton />
                            </div>
                        </div>
                    </div>
                    <div className='table-toolbar-group-right'>
                        <div className='table-toolbar-column justify-content-center'>
                            <div className='label label-muted label-filled'>
                                <FormattedPlural
                                    one={
                                        <FormattedMessage
                                            id='intl-msg:network.partnersTable.numPartners.one'
                                            values={{ count: totalPartners }}
                                        />
                                    }
                                    other={
                                        <FormattedMessage
                                            id='intl-msg:network.partnersTable.numPartners.other'
                                            values={{ count: totalPartners }}
                                        />
                                    }
                                    zero={<FormattedMessage id='intl-msg:network.partnersTable.numPartners.zero' />}
                                    value={totalPartners}
                                />
                            </div>
                        </div>
                        <div className='table-toolbar-column'>
                            <TableSearch
                                value={searchValue}
                                onChange={setSearchValue}
                                placeholder={intl.formatMessage({
                                    id: 'intl-msg:network.partnersTable.search.placeholder',
                                })}
                            />
                        </div>
                    </div>
                </div>
            </TableToolbar>
            <PartnerTable partners={filteredPartners} isEmptyList={isEmptyList} isNotFoundList={isNotFoundList} />
        </div>
    );
};

export default Partners;

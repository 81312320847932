import Spinner from '@rio-cloud/rio-uikit/Spinner';

import { useFetchAccountProfileQuery, useFetchPartnersQuery } from '../services/partnerNetworkApi';
import Partners from '../features/table/Partners';
import ServiceInfoDialog from '../features/serviceInfoDialog/ServiceInfoDialog';
import { useAppSelector } from '../configuration/setup/hooks';
import { getUserProfile } from '../configuration/login/loginSlice';
import FeedbackPanel from '../features/feedbackPanel/FeedbackPanel';

const Overview = () => {
    const accountId = useAppSelector(getUserProfile)?.account;

    const isLoading = () => {
        const { isLoading: isPartnersLoading } = useFetchPartnersQuery(undefined, {
            pollingInterval: 20000,
        });
        // Already load own account profile so it is available in dialog
        const { isLoading: isAccountProfileLoading } = useFetchAccountProfileQuery(accountId, { skip: !accountId });
        return isPartnersLoading && isAccountProfileLoading;
    };

    if (isLoading()) {
        return (
            <div className='height-100vh'>
                <Spinner isDoubleSized />
            </div>
        );
    }

    return (
        <>
            <Partners />
            <ServiceInfoDialog />
            <FeedbackPanel />
        </>
    );
};

export default Overview;

import CustomState from '@rio-cloud/rio-uikit/CustomState';
import { FormattedMessage, useIntl } from 'react-intl';

type InvalidInvitationStateProps = {
    onClick: () => void;
};

const InvalidInvitationState = (props: InvalidInvitationStateProps) => {
    const { onClick } = props;
    const intl = useIntl();

    return (
        <CustomState
            fullWidth
            outerClassName='shadow-default'
            headline={intl.formatMessage({ id: "intl-msg:network.invitation.invalidInvitation.title" })}
            message={
                <div className='margin-top-25'>
                    <FormattedMessage id="intl-msg:network.invitation.invalidInvitation.message" />
                </div>
            }
            icons={[
                {
                    name: 'rioglyph rioglyph-flash',
                    color: 'text-color-highlight',
                    className: 'text-size-300pct',
                },
            ]}
            buttons={[
                {
                    text: <FormattedMessage id="intl-msg:network.invitation.invalidInvitation.backToOverview" />,
                    onClick,
                },
            ]}
        />
    );
};

export default InvalidInvitationState;

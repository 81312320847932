import { FormattedMessage } from 'react-intl';

const ScreenshotOfShareInvitationLink = () => (
    <div className='pointer-events-none user-select-none bg-lighter'>
        <div className='modal show modal-enter-done position-relative'>
            <div className='modal-dialog modal-sm margin-15'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <div className='modal-header-text'>
                            <div className='modal-header-title'>
                                <FormattedMessage id='intl-msg:network.addPartner.step2.title' />
                            </div>
                        </div>
                        <div className='modal-header-buttons'>
                            <button type='button' className='btn btn-icon-only btn-muted modal-header-close close'>
                                <span className='rioglyph rioglyph-remove' />
                            </button>
                        </div>
                    </div>
                    <div className='modal-body display-flex'>
                        <div className='position-relative width-100pct'>
                            <div className='padding-bottom-20 text-color-darker'>
                                Ein Einladungslink ist 24 Stunden gültig. Der Link kann einmalig von einem Empfänger
                                genutzt werden um die Partnerschaft herzustellen.
                            </div>
                            <form>
                                <div className='form-group '>
                                    <label className='control-label'>E-Mail-Adresse*</label>
                                    <div className='input-group'>
                                        <span className='input-group-addon'>
                                            <span className='rioglyph rioglyph-envelope' />
                                        </span>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Geben Sie eine gültige E-Mail-Adresse ein'
                                            name='emailAddress'
                                        />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Name des Empfängers</label>
                                    <div className='input-group'>
                                        <span className='input-group-addon'>
                                            <span className='rioglyph rioglyph-user' />
                                        </span>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Geben Sie den Namen des Empfängers ein (optional)'
                                            name='name'
                                        />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Vorschau</label>
                                    <div className='text-size-11 border border-color-default border-width-1 border-style-dashed padding-5 pointer-events-none'>
                                        Hallo,
                                        <br />
                                        <br />
                                        Sie haben eine neue Anfrage für eine Partnerverbindung von Happy Path Logistics.
                                        <br />
                                        <br />
                                        Eine Partneranbindung bietet viele Vorteile für ihre Zusammenarbeit, wie z.B.:
                                        <ul>
                                            <li>
                                                Austausch von Daten, die für die Planung und Durchführung eines
                                                Transports relevant sind, mit anderen Parteien
                                            </li>
                                            <li>
                                                Vereinfachung der Kommunikation sowohl mit Ihren Kunden als auch mit
                                                Ihren Subunternehmern
                                            </li>
                                            <li>Sicherer und datenschutzkonformer Datenaustausch</li>
                                        </ul>
                                        <button type='button' className='btn btn-default btn-xs'>
                                            Verbindungsanfrage einsehen
                                        </button>
                                        <br />
                                        <br />
                                        Viele Grüße,
                                        <br />
                                        Ihr RIO Team
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ScreenshotOfShareInvitationLink;

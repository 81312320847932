import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Invitation } from '../services/partnerNetwork.types';
import {
    useFetchAccountProfileQuery,
    useFetchInvitationQuery,
    useUpdateInvitationMutation,
} from '../services/partnerNetworkApi';
import { DEFAULT_ROUTE } from '../routes/routes';
import { partnerSelected, showServiceInfoDialog } from '../layout/appSlice';
import { useAppDispatch } from '../configuration/setup/hooks';
import PendingInvitationState from '../features/partnerInvitation/PendingInvitationState';
import LoadingInvitationState from '../features/partnerInvitation/LoadingInvitationState';
import InvalidInvitationState from '../features/partnerInvitation/InvalidInvitationState';
import { getTrackingAttributes, TRACKING_CATEGORIES } from '../configuration/setup/googleAnalytics';

export const INVITATION_PARTNER_QUERY = 'partner';
export const INVITATION_ACCESS_TOKEN_QUERY = 'accessToken';

const PartnerInvitation = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selectPartner = (selectedPartnerId: string | undefined) => dispatch(partnerSelected(selectedPartnerId));

    const [updateInvitation] = useUpdateInvitationMutation();

    const location = useLocation();
    const queryString = location.search;

    const urlParams = new URLSearchParams(queryString);
    const partnerId = urlParams.get(INVITATION_PARTNER_QUERY) || undefined;
    const id = urlParams.get(INVITATION_ACCESS_TOKEN_QUERY) || undefined;

    const { data: invitation, isLoading: isLoadingInvitation } = useFetchInvitationQuery(
        { id, partnerId },
        { skip: !(id && partnerId) }
    );
    const { data: accountProfile, isLoading: isLoadingAccountProfile } = useFetchAccountProfileQuery(partnerId, {
        skip: !partnerId,
    });

    useEffect(() => {
        selectPartner(undefined);
    }, []);

    const handleAccept = () => {
        if (invitation) {
            const acceptedInvitation: Invitation = {
                ...invitation,
                status: 'accepted',
            };
            updateInvitation(acceptedInvitation);
            selectPartner(acceptedInvitation.partnerId);
        }

        // Hide welcome screen right after accepting the partner invitation
        dispatch(showServiceInfoDialog(false));

        navigate(DEFAULT_ROUTE);
    };

    const handleLeave = () => navigate(DEFAULT_ROUTE);

    if (isLoadingInvitation || isLoadingAccountProfile) {
        return <LoadingInvitationState />;
    }

    if (invitation && invitation.status === 'pending') {
        return (
            <PendingInvitationState
                {...getTrackingAttributes({
                    trigger: 'click',
                    category: TRACKING_CATEGORIES.INVITATION,
                    action: 'Accept partner invitation clicked',
                    label: 'Accept partner invitation clicked',
                })}
                accountProfile={accountProfile}
                onClick={() => handleAccept()}
            />
        );
    }

    return <InvalidInvitationState onClick={() => handleLeave()} />;
};

export default PartnerInvitation;

import { FormattedMessage } from 'react-intl';

import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import type { Partner } from '../../services/partnerNetwork.types';
import PartnershipStatus from '../../components/PartnershipStatus';
import { useState } from 'react';
import EditPartnerDialog from '../editPartnerDialog/EditPartnerDialog';

type PartnerSummaryProps = {
    partner: Partner;
};

const PartnerSummary = ({ partner }: PartnerSummaryProps) => {
    const [isEditDialogShown, showEditDialog] = useState(false);
    const partnerIsDeleted = partner.status === 'rescinded';

    return (
        <>
            <div
                className='display-flex align-items-center margin-bottom-15'
                {...getTrackingAttributes({
                    trigger: 'visibility',
                    category: TRACKING_CATEGORIES.OVERVIEW,
                    action: 'Sidebar shown',
                    label: 'Sidebar shown',
                })}
            >
                <div
                    className={`margin-right-15 bg-lightest rounded-circle padding-25 
            display-grid place-items-center text-size-16`}
                >
                    <span className='rioglyph rioglyph-user text-color-dark text-size-200pct' />
                </div>
                <div className='display-flex gap-25'>
                    <div className='flex-1-1'>
                        <label className='margin-bottom-0'>
                            <FormattedMessage id='intl-msg:network.label.companyName' />
                        </label>
                        <div className='margin-bottom-5'>
                            <div>{partner?.name}</div>
                        </div>
                    </div>
                    <div className='flex-1-1'>
                        <label className='margin-bottom-0'>Verbindung</label>
                        <div>
                            <PartnershipStatus partner={partner} />
                        </div>
                    </div>
                    <div className='flex-1-1'>
                        <div className='flex-1-1'>
                            <label className='margin-bottom-0'>
                                <FormattedMessage id='intl-msg:network.label.description' />
                            </label>
                            <div className='margin-bottom-5'>{partner?.description ? partner.description : '-'}</div>
                        </div>
                    </div>
                    {!partnerIsDeleted && (
                        <div className='flex-1-1'>
                            <button
                                className='btn btn-muted btn-xs btn-icon-only margin-left-5'
                                onClick={() => showEditDialog(true)}
                            >
                                <span className='rioglyph rioglyph-pencil' />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {isEditDialogShown && (
                <EditPartnerDialog partner={partner} onClose={() => showEditDialog(false)} show={isEditDialogShown} />
            )}
        </>
    );
};

export default PartnerSummary;

import { useState, useEffect } from 'react';

import type { Partner } from '../../services/partnerNetwork.types';
import { dataSharingAgreements } from '../../data/demoData';
import { featureToggles } from '../../configuration/setup/featureToggles';
import InvitationDetails from './InvitationDetails';
import DataSharingAgreementForm from './DataSharingAgreementForm';
import Counts from '../../components/Counts';
import PartnerSummary from './PartnerSummary';

type PartnerDetailsProps = {
    partner: Partner;
    onChange?: Function;
};

type PartnerDetailsTabs = 'AGREEMENTS' | 'CONTRACTS';

const PartnerDetails = (props: PartnerDetailsProps) => {
    const { partner, onChange } = props;

    const [tab, setTab] = useState<PartnerDetailsTabs>('AGREEMENTS');

    useEffect(() => {
        if (onChange) {
            onChange(getUpdatedPartner());
        }
    }, [partner]);

    const getUpdatedPartner = (): Partner => {
        return {
            ...partner,
        };
    };

    return (
        <div>
            <PartnerSummary partner={partner} />

            {featureToggles.poc && partner.status !== 'pending' ? (
                <>
                    <ul className='nav nav-pills nav-pills-filled nav-justified'>
                        <li className={tab === 'AGREEMENTS' ? 'active' : ''} onClick={() => setTab('AGREEMENTS')}>
                            <div>
                                <span className='padding-right-5'>Datenfreigaben</span>
                                <Counts count={dataSharingAgreements.length} requestsCount={1} />
                            </div>
                        </li>
                    </ul>
                    {tab === 'AGREEMENTS' && <DataSharingAgreementForm />}
                </>
            ) : (
                <InvitationDetails partner={partner} />
            )}
        </div>
    );
};

export default PartnerDetails;

const localStorage = window.localStorage;

const STORAGE_PREFIX = 'partnernetwork.';

const save = (key: string, value: any, prefix: string = STORAGE_PREFIX) => {
    try {
        localStorage.setItem(`${prefix}${key}`, JSON.stringify(value));
        return value;
    } catch (_) {
        // Intentionally left blank
    }
};

const removeItem = (key: string, prefix: string = STORAGE_PREFIX) => {
    try {
        localStorage.removeItem(`${prefix}${key}`);
    } catch (_) {
        // Intentionally left blank
    }
};

const load = (key: string, prefix: string = STORAGE_PREFIX): string | null => {
    try {
        const value = localStorage.getItem(`${prefix}${key}`);
        return value ? JSON.parse(value) : null;
    } catch (_) {
        // Intentionally left blank
        return null;
    }
};

export const storage = { save, load, removeItem };

import { Partner } from '../../services/partnerNetwork.types';
import { FormEditPartner } from './EditPartnerDialog';
import { useStorePartnerMutation } from '../../services/partnerNetworkApi';

const useEditPartner = () => {
    const [updatePartner] = useStorePartnerMutation();
    const handleEditPartner = async (partner: Partner, formEditPartner: FormEditPartner) => {
        const editedPartner = {
            id: partner.id,
            name: formEditPartner.name,
            description: formEditPartner.description,
            status: partner.status,
            accountProfile: partner.accountProfile,
        } as Partner;
        await updatePartner(editedPartner);
    };

    return { handleEditPartner };
};

export default useEditPartner;

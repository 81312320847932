import { useState, useEffect, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import isEmpty from 'lodash/fp/isEmpty';
import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Notification from '@rio-cloud/rio-uikit/Notification';
import useClipboard from '@rio-cloud/rio-uikit/useClipboard';

import { INVITATION_PARTNER_QUERY, INVITATION_ACCESS_TOKEN_QUERY } from '../../pages/PartnerInvitation';
import type { Partner, Invitation, EmailInvitation } from '../../services/partnerNetwork.types';
import CreateStepOne from './CreateStepOne';
import CreateStepTwo from './CreateStepTwo';
import {
    useCreateInvitationMutation,
    useStorePartnerMutation,
    useCreateEmailInvitationMutation,
} from '../../services/partnerNetworkApi';
import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getInvitationDialogPartner } from '../../layout/appSlice';

export type FormEmailData = {
    name: string;
    emailAddress: string;
};

type CreatePartnerDialogProps = {
    show: boolean;
    onClose: () => void;
};

const buildInvitationLink = (baseUrl: string, invitation: Invitation): string => {
    const partnerId = invitation.partnerId;
    const accessToken = invitation.id;
    const path = '/#/invitation?';
    const partnerQuery = `${INVITATION_PARTNER_QUERY}=${partnerId}`;
    const accessTokenQuery = `${INVITATION_ACCESS_TOKEN_QUERY}=${accessToken}`;

    return `${baseUrl}${path}${partnerQuery}&${accessTokenQuery}`;
};

const mapToEmailInvitation = (invitation: Invitation, formEmailData: FormEmailData): EmailInvitation => ({
    partnerId: invitation.partnerId,
    invitationId: invitation.id,
    emailAddress: formEmailData.emailAddress,
    inviteeName: formEmailData.name,
    language: 'de',
});

const CreatePartnerDialog = (props: CreatePartnerDialogProps) => {
    const { show, onClose } = props;

    const intl = useIntl();
    const clipboard = useClipboard();

    const invitationDialogPartner = useAppSelector(getInvitationDialogPartner);

    const [updatePartner] = useStorePartnerMutation();
    const [createInvitation] = useCreateInvitationMutation();
    const [createEmailInvitation] = useCreateEmailInvitationMutation();

    const [partnerName, setPartnerName] = useState<string | undefined>();
    const [partnerDescription, setPartnerDescription] = useState<string | undefined>();

    const [partner, setPartner] = useState<Partner | undefined>();
    const [invitation, setInvitation] = useState<Invitation | undefined>();
    const [invitationLink, setInvitationLink] = useState<string>();

    const emailForm = useForm<FormEmailData>({
        mode: 'onSubmit',
    });

    const onSubmit = async (formEmailData: FormEmailData) => {
        if (!invitation) {
            return;
        }
        const emailInvitation = mapToEmailInvitation(invitation, formEmailData);
        createEmailInvitation(emailInvitation);
        handleClose();
    };

    const createNewInvitationLink = async () => {
        if (!partner) {
            return;
        }
        const result = await createInvitation(partner.id);
        if ('data' in result && result.data) {
            setInvitation(result.data);
        }
    };

    useEffect(() => {
        setPartner(invitationDialogPartner);
    }, [invitationDialogPartner]);

    useEffect(() => {
        createNewInvitationLink();
    }, [partner]);

    useEffect(() => {
        if (clipboard.copied) {
            Notification.success(
                intl.formatMessage({
                    id: 'intl-msg:network.addPartner.invitationLinkCopied',
                })
            );
        }
    }, [clipboard.copied]);

    useEffect(() => {
        if (invitation) {
            setInvitationLink(buildInvitationLink(window.location.origin, invitation));
        }
    }, [invitation]);

    const reset = () => {
        setPartnerName(undefined);
        setPartnerDescription(undefined);
        setPartner(undefined);
        setInvitation(undefined);
        setInvitationLink(undefined);
        emailForm.reset();
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleCreatePartner = async () => {
        const newPartner = {
            id: uuid(),
            name: partnerName,
            description: partnerDescription,
            status: 'pending',
        } as Partner;
        await updatePartner(newPartner);

        setPartner(newPartner);
    };

    const handleChangePartnerDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setPartnerDescription(event.target.value);
    };

    const footerStepOne = (
        <div className='btn-toolbar justify-content-end'>
            <Button onClick={handleClose}>
                <FormattedMessage id='intl-msg:network.label.cancel' />
            </Button>
            <Button
                bsStyle={'primary'}
                className='btn-icon-right'
                onClick={handleCreatePartner}
                disabled={isEmpty(partnerName)}
            >
                <span className='rioglyph rioglyph-chevron-right' />
                <FormattedMessage id='intl-msg:network.label.addPartner' />
            </Button>
        </div>
    );

    const footerStepTwo = (
        <div className='btn-toolbar justify-content-end'>
            <button
                className='btn btn-default'
                type='button'
                onClick={() => clipboard.copy(invitationLink)}
                {...getTrackingAttributes({
                    trigger: 'click',
                    category: TRACKING_CATEGORIES.INVITATION,
                    action: 'Copy invitation link clicked',
                    label: 'Copy invitation link clicked',
                })}
            >
                <span className='rioglyph rioglyph-link' />
                <FormattedMessage id='intl-msg:network.addPartner.copyInvitationLink' />
            </button>
            <Button
                type='submit'
                onClick={emailForm.handleSubmit(onSubmit)}
                className='btn btn-primary'
                {...getTrackingAttributes({
                    trigger: 'click',
                    category: TRACKING_CATEGORIES.INVITATION,
                    action: 'Send email invitation button clicked',
                    label: 'Send email invitation button clicked',
                })}
            >
                <span className='rioglyph rioglyph-envelope' />
                <FormattedMessage id='intl-msg:network.label.sendEmailInvitation' />
            </Button>
        </div>
    );

    const isFirstStep = !partner;

    const title = isFirstStep ? (
        <FormattedMessage id='intl-msg:network.addPartner.step1.title' />
    ) : (
        <FormattedMessage id='intl-msg:network.addPartner.step2.title' />
    );

    return (
        <Dialog
            show={show}
            title={title}
            footer={isFirstStep ? footerStepOne : footerStepTwo}
            body={
                isFirstStep ? (
                    <CreateStepOne
                        partnerName={partnerName}
                        onChangePartnerName={setPartnerName}
                        partnerDescription={partnerDescription}
                        onChangePartnerDescription={handleChangePartnerDescription}
                    />
                ) : (
                    <CreateStepTwo form={emailForm} />
                )
            }
            bsSize={Dialog.SIZE_SM}
            onHide={handleClose}
            showCloseButton
        />
    );
};

export default CreatePartnerDialog;

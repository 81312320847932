import { FormattedMessage } from 'react-intl';

import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { serviceInfoChapter, SERVICE_INFO_CHAPTER_HELP, showServiceInfoDialog } from '../../layout/appSlice';

const HelpButton = () => {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(serviceInfoChapter(SERVICE_INFO_CHAPTER_HELP));
        dispatch(showServiceInfoDialog(true));
    };

    return (
        <button
            className='btn btn-default'
            role='button'
            {...getTrackingAttributes({
                trigger: 'click',
                category: TRACKING_CATEGORIES.OVERVIEW,
                action: 'Help button clicked',
                label: 'Help button clicked',
            })}
            onClick={handleClick}
        >
            <span className='rioglyph rioglyph-question-sign' />
            <FormattedMessage id='intl-msg:network.label.help' />
        </button>
    );
};

export default HelpButton;

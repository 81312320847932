import packageJson from '../package.json';

export type ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        MAP_CREDENTIALS_SERVICE: string | undefined;
        HERE_AUTO_SUGGEST_SERVICE: string | undefined;
        PARTNER_NETWORK_SERVICE: string | undefined;
        ACCOUNTS_SERVICE: string | undefined;
        CONTACT_FORM_API: string | undefined;
        CONTACT_FORM_API_KEY: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    enableMockServer: boolean;
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
};

const getBoolValue = (value: string | undefined): boolean => {
    return value === 'true';
};

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        MAP_CREDENTIALS_SERVICE: import.meta.env.VITE_MAP_CREDENTIALS_SERVICE,
        HERE_AUTO_SUGGEST_SERVICE: import.meta.env.VITE_HERE_AUTO_SUGGEST_SERVICE,
        PARTNER_NETWORK_SERVICE: import.meta.env.VITE_PARTNER_NETWORK_SERVICE,
        ACCOUNTS_SERVICE: import.meta.env.VITE_ACCOUNTS_SERVICE,
        CONTACT_FORM_API: import.meta.env.VITE_CONTACT_FORM,
        CONTACT_FORM_API_KEY: import.meta.env.VITE_CONTACT_FORM_API_KEY,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '8fea4370-b265-4678-a9d1-2141431c6ff5',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'phone',
            'menu.read',
            'partner-network.read',
            'partner-network.write',
            'map.read',
        ],
        mockAuthorization: import.meta.env.DEV,
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolValue(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: import.meta.env.DEV,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'partner-network-web',
};

import CustomState from '@rio-cloud/rio-uikit/CustomState';
import { FormattedMessage, useIntl } from 'react-intl';

import { AccountProfile } from '../../services/partnerNetwork.types';

type PendingInvitationStateProps = {
    accountProfile?: AccountProfile;
    onClick: () => void;
};

const PendingInvitationState = (props: PendingInvitationStateProps) => {
    const { accountProfile, onClick } = props;
    const intl = useIntl();

    return (
        <CustomState
            fullWidth
            outerClassName='shadow-default'
            headline={accountProfile?.displayName ? intl.formatMessage({ id: "intl-msg:network.invitation.pendingInvitation.titleWithCompanyName" }, {companyName: accountProfile.displayName }) : intl.formatMessage({ id: "intl-msg:network.invitation.pendingInvitation.title" })}
            message={
                <div>
                    <div className='margin-top-25'>
                        <FormattedMessage id="intl-msg:network.invitation.pendingInvitation.message" />
                    </div>
                    <div className='margin-top-25'>
                        <ul className='feature-list'>
                            <li><FormattedMessage id="intl-msg:network.invitation.pendingInvitation.yourAccountId" /></li>
                            <li><FormattedMessage id="intl-msg:network.invitation.pendingInvitation.yourAccountName" /></li>
                        </ul>
                    </div>
                    <div className='margin-top-25'>
                        <FormattedMessage id="intl-msg:network.invitation.pendingInvitation.consent" />
                    </div>
                </div>
            }
            icons={[
                {
                    name: 'rioglyph rioglyph-send',
                    color: 'text-color-highlight',
                    className: 'text-size-300pct',
                },
            ]}
            buttons={[
                {
                    text: <FormattedMessage id="intl-msg:network.invitation.pendingInvitation.acceptInvitation" />,
                    onClick,
                },
            ]}
        />
    );
};

export default PendingInvitationState;

import { ActionReducerMapBuilder, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from './../configuration/setup/store';
import { Partner, PartnerList } from './../services/partnerNetwork.types';
import { partnerNetworkApi } from './../services/partnerNetworkApi';
import { Account } from './../services/accounts.types';
import { accountsApi } from './../services/accountsApi';

export const SERVICE_INFO_CHAPTER_WELCOME = 'welcome';
export const SERVICE_INFO_CHAPTER_HELP = 'firstSteps';

export type AppState = {
    sessionExpiredAcknowledged: boolean;
    account?: Account;
    partners?: PartnerList;
    selectedPartnerId?: string;
    invitationDialogPartner?: Partner;
    showInvitationDialog: boolean;
    showServiceInfoDialog: boolean;
    serviceInfoChapter: string;
};

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    account: undefined,
    partners: undefined,
    selectedPartnerId: undefined,
    invitationDialogPartner: undefined,
    showInvitationDialog: false,
    showServiceInfoDialog: !document.cookie.includes('welcome_shown=true'),
    serviceInfoChapter: SERVICE_INFO_CHAPTER_WELCOME,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: state => {
            state.sessionExpiredAcknowledged = true;
        },
        accountStored: (state, action: PayloadAction<Account>) => {
            state.account = action.payload;
        },
        partnerSelected: (state, action: PayloadAction<string | undefined>) => {
            state.selectedPartnerId = action.payload;
        },
        partnersStored: (state, action: PayloadAction<PartnerList>) => {
            state.partners = action.payload;
        },
        showInvitationDialog: (state, action: PayloadAction<{ showDialog: boolean; partner?: Partner }>) => {
            state.invitationDialogPartner = action.payload.partner;
            state.showInvitationDialog = action.payload.showDialog;
        },
        showServiceInfoDialog: (state, action: PayloadAction<boolean>) => {
            state.showServiceInfoDialog = action.payload;
            document.cookie = 'welcome_shown=true; expires=Thu, 30 Jun 2023 12:00:00 UTC; path=/';
        },
        serviceInfoChapter: (state, action: PayloadAction<string>) => {
            state.serviceInfoChapter = action.payload;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<AppState>) => {
        builder.addMatcher(
            accountsApi.endpoints.fetchAccount.matchFulfilled,
            (state, action: PayloadAction<Account>) => {
                state.account = action.payload;
            }
        );
        builder.addMatcher(
            partnerNetworkApi.endpoints.fetchPartners.matchFulfilled,
            (state, action: PayloadAction<PartnerList>) => {
                state.partners = action.payload;
            }
        );
    },
});

export const {
    hideSessionExpiredDialog,
    partnerSelected,
    partnersStored,
    showInvitationDialog,
    showServiceInfoDialog,
    serviceInfoChapter,
} = appSlice.actions;

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;

export const getPartnerList = (state: RootState) => state.app.partners;
export const getSelectedPartnerId = (state: RootState) => state.app.selectedPartnerId;

export const getSelectedPartner = createSelector(
    getPartnerList,
    getSelectedPartnerId,
    (partnerList, selectedPartnerId) => partnerList?.items.find(partner => partner.id === selectedPartnerId)
);

export const getInvitationDialogPartner = (state: RootState) => state.app?.invitationDialogPartner;
export const getShowCreateDialog = (state: RootState) => state.app?.showInvitationDialog;
export const getShowServiceInfoDialog = (state: RootState) => state.app?.showServiceInfoDialog;
export const getServiceInfoChapter = (state: RootState) => state.app?.serviceInfoChapter;

export default appSlice.reducer;

import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import SplitDialog from '@rio-cloud/rio-uikit/SplitDialog';

import ServiceInfoDialogMenu from './ServiceInfoDialogMenu';
import ServiceInfoDialogPages from './ServiceInfoDialogPages';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import {
    getServiceInfoChapter,
    getShowServiceInfoDialog,
    serviceInfoChapter,
    showServiceInfoDialog,
} from '../../layout/appSlice';

const ServiceInfoDialog = () => {
    const dispatch = useAppDispatch();
    const showDialog = useAppSelector(getShowServiceInfoDialog);
    const activeChapter = useAppSelector(getServiceInfoChapter);

    const handleClose = () => dispatch(showServiceInfoDialog(false));

    const handleChapterClick: React.MouseEventHandler<HTMLElement> = event => {
        const key = event?.currentTarget?.getAttribute('data-name');
        if (key) {
            dispatch(serviceInfoChapter(key));
        }
    };

    return (
        <SplitDialog
            show={showDialog}
            title={<FormattedMessage id='intl-msg:network.moduleName' />}
            footer={
                <div className='btn-toolbar justify-content-end'>
                    <Button onClick={handleClose}>
                        <FormattedMessage id='intl-msg:network.label.close' />
                    </Button>
                </div>
            }
            leftContent={<ServiceInfoDialogMenu chapter={activeChapter} onClick={handleChapterClick} />}
            rightContent={<ServiceInfoDialogPages chapter={activeChapter} />}
            bsSize={Dialog.SIZE_LG}
            onClose={handleClose}
            showCloseButton
        />
    );
};

export default ServiceInfoDialog;

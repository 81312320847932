import './App.css';

import { FormattedMessage, IntlProvider } from 'react-intl';
import { Routes, Route } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';

import { DEFAULT_LOCALE } from './../configuration/lang/lang';
import { isUserSessionExpired } from './../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from './../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from './../configuration/lang/langSlice';
import { DEFAULT_ROUTE, INVITATION_ROUTE } from '../routes/routes';
import DefaultRedirect from '../routes/DefaultRedirect';
import RouteUpdater from '../routes/RouteUpdater';

import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from './appSlice';
import AppHeader from '../features/header/AppHeader';
import Overview from '../pages/Overview';
import PartnerSidebar from '../features/sidebar/PartnerSidebar';
import PartnerInvitation from '../pages/PartnerInvitation';
import { featureToggles } from '../configuration/setup/featureToggles';
import FeedbackPanel from '../features/feedbackPanel/FeedbackPanel';

const App = () => {
    const dispatch = useAppDispatch();

    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);

    if (!(displayMessages && userLocale)) {
        return null;
    }

    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog);
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;

    const BetaBanner = () => {
        return (
            <ApplicationLayoutBodyBanner backgroundColor='bg-info'>
                <div className='display-flex flex-row justify-content-center'>
                    <div className={'label label-condensed'}>
                        <FormattedMessage id='intl-msg:network.banner.beta' />
                    </div>
                    <div className={'padding-left-10'}>
                        <FormattedMessage id='intl-msg:network.banner.message' />
                    </div>
                </div>
            </ApplicationLayoutBodyBanner>
        );
    };

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ApplicationLayout className={'PartnerNetwork'}>
                <ApplicationLayout.Header>
                    <AppHeader />
                </ApplicationLayout.Header>
                <ApplicationLayout.Sidebar className='right'>
                    <PartnerSidebar />
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Body banner={<BetaBanner />}>
                    <NotificationsContainer />
                    <SessionExpiredDialog
                        locale={userLocale}
                        onClose={handleSessionExpiredDialogClose}
                        show={showSessionExpired}
                    />
                    <Routes>
                        <Route path={DEFAULT_ROUTE} element={<Overview />} />
                        <Route path={INVITATION_ROUTE} element={<PartnerInvitation />} />
                        <Route path='*' element={<DefaultRedirect />} />
                    </Routes>
                    <RouteUpdater />
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default App;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../config';
import { UserProfile } from '../configuration/login/loginSlice';
import { ContactRequest } from './contactForm.types';

const prepareHeaders = (headers: Headers) => {
    const apiKey = config.backend.CONTACT_FORM_API_KEY;
    if (apiKey) {
        headers.set('X-API-Key', apiKey);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

const handleResponse = async (response: Response) => {
    if (response.status === 401) {
        throw new Error(`${response.status} Unauthorized: ${response.statusText}`);
    }
    throw new Error(`${response.status} Backend error: ${response.statusText}`);
};

export const contactFormApi = createApi({
    // reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.CONTACT_FORM_API,
        prepareHeaders,
    }),
    tagTypes: ['ContactApi'],
    endpoints: builder => ({
        sendFeedback: builder.mutation<ContactRequest | null, { message: string; userProfile: UserProfile | null }>({
            query: ({ message, userProfile }) => ({
                url: '/contacts',
                method: 'POST',
                body: {
                    account_id: userProfile?.account,
                    user_id: userProfile?.sub,
                    first_name: userProfile?.givenName,
                    last_name: userProfile?.familyName,
                    email: userProfile?.email || 'no-email-set@do-not-reply.com',
                    locale: userProfile?.locale,
                    contact_reason: 'feedback',
                    message,
                },
                responseHandler: async (response: Response) => handleResponse(response),
            }),
        }),
    }),
});

export const { useSendFeedbackMutation } = contactFormApi;

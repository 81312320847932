type CountsProps = {
    className?: string;
    count?: number;
    requestsCount?: number;
};

const Counts = (props: CountsProps) => {
    const { className = '', count, requestsCount } = props;

    if (!(count || requestsCount)) {
        return null;
    }

    const classNames = `label label-condensed label-muted label-filled text-size-12 ${className}`;

    return (
        <span className={classNames}>
            {count ? <span>{count}</span> : null}
            {count && requestsCount ? <span className='text-color-gray'> | </span> : null}
            {requestsCount ? <span className='text-color-primary'>{requestsCount}</span> : null}
        </span>
    );
};

export default Counts;
